// src/components/auth/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithPopup,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { auth, googleProvider, db } from '../../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const AuthContext = createContext();

const getBrowserLanguage = () => {
  const supportedLanguages = ['ko', 'en', 'ja'];
  
  // 저장된 선호 언어가 있으면 사용
  const savedLanguage = localStorage.getItem('preferredLanguage');
  if (savedLanguage && supportedLanguages.includes(savedLanguage)) {
    return savedLanguage;
  }

  // 없으면 브라우저 언어 사용
  const browserlang = navigator.language.toLowerCase().split('-')[0];
  return supportedLanguages.includes(browserlang) ? browserlang : 'en';
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(() => getBrowserLanguage());

  const saveUserLanguage = async (userId, language) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { language }, { merge: true });
    } catch (error) {
      console.error('Error saving user language:', error);
    }
  };

  const loadUserLanguage = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists() && userDoc.data().language) {
        return userDoc.data().language;
      }
      return null;
    } catch (error) {
      console.error('Error loading user language:', error);
      return null;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result;
    } catch (error) {
      console.error('Google sign in error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  useEffect(() => {
    const handleLanguageChange = async (currentUser) => {
      try {
        if (currentUser) {
          const userLanguage = await loadUserLanguage(currentUser.uid);
          if (userLanguage) {
            await i18n.changeLanguage(userLanguage);
            setCurrentLanguage(userLanguage);
            localStorage.setItem('preferredLanguage', userLanguage);
          } else {
            const preferredLanguage = localStorage.getItem('preferredLanguage') || currentLanguage;
            await saveUserLanguage(currentUser.uid, preferredLanguage);
            await i18n.changeLanguage(preferredLanguage);
            setCurrentLanguage(preferredLanguage);
          }
        } else {
          const preferredLanguage = localStorage.getItem('preferredLanguage') || getBrowserLanguage();
          await i18n.changeLanguage(preferredLanguage);
          setCurrentLanguage(preferredLanguage);
          localStorage.setItem('preferredLanguage', preferredLanguage);
        }
      } catch (error) {
        console.error('Language change error:', error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        await handleLanguageChange(currentUser);
      } catch (error) {
        console.error('Auth state change error:', error);
      } finally {
        setUser(currentUser);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [currentLanguage, i18n]);

  const value = {
    user,
    loading,
    signInWithGoogle,
    logout,
    saveUserLanguage: user ? (language) => saveUserLanguage(user.uid, language) : null,
    setCurrentLanguage
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};